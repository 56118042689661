
export const state = {
    backoffice_registration_loaded: false,
    backoffice_registration_data: {
        searchPersonalDetailQuery: null,
        validationStatus: null
    }
};

export const getters = {
    getterBackofficeRegistrationLoaded: state => state.backoffice_registration_loaded,
    getterBackofficeRegistrationData: state => state.backoffice_registration_data,
};

export const mutations = {
    SET_BACKOFFICE_REGISTRATION_LOADED(state, newValue) {
        state.backoffice_registration_loaded = newValue
    },
    SET_BACKOFFICE_REGISTRATION_DATA(state, newValue) {
        state.backoffice_registration_data = newValue
    },
};

export const actions = {
    initBackofficeRegistrationTabValues({ commit }) {
        commit('SET_BACKOFFICE_REGISTRATION_LOADED', false);
    },
    setBackofficeRegistrationTabValues({ commit }, values) {
        commit('SET_BACKOFFICE_REGISTRATION_LOADED', true);
        commit('SET_BACKOFFICE_REGISTRATION_DATA', values.data);
    },
};
